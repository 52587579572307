section#canban {
  max-width: 800px;
  padding: 20px;
  border: 2px solid #3b414d;
  border-radius: 10px;
}

#canban form, #canban .signout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto 0;
  max-width: 300px;
}

#canban input, #canban button {
  height: 40px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #d7d7d7;
  transition: background .15s ease;
}

#canban input:focus, #canban button:hover {
  background: #3b414d;
}

#canban input, #canban .nota p {
  width: 100%;
  margin-right: 6px;
  text-align: left;
  font-size: 20px;
}

#canban input {
  border: 2px solid #3b414d;
}

#canban button {
  width: 100%;
  cursor: pointer;
}

#canban .nota {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#canban form button, #canban .nota button {
  width: 40px;
  font-size: 20px;
}

#canban .signin img {
  width: 40px;
  height: 40px;
}

#canban .signin {
  width: 50px;
  height: 50px;
}

#brand {
	position: fixed;
	bottom: 5px;
	left: 10px;
  color: #fff2
}