#listOfGifs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.gif {
  height: 200px;
  font-size: .8em;
  color: white;
  text-decoration: none;
}

.gif img, .gif h3 {
  height: 200px;
}

button, a.link {
  color: white;
  background: #282c34;
  padding: 5px;
  border: 1px solid lightblue;
  border-radius: 5px;
  font-size: 14px;
}

button:hover {
  background: #3f4550;
}

span.loading {
  display: block;
  font-size: 40px;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg)
  }
}

form {
  display: flex;
  justify-content: center;
}

form input {
  width: 80%;
  max-width: 300px;
  padding: 5px;
  cursor: auto;
  border-radius: 5px;
  border: 2px solid #3f4550;
  color: #fff;
  background: #3f4550;
}

form input:focus {
  outline: none;
  background: #111;
  border: 2px solid rebeccapurple;
}

form a {
  color: white;
}