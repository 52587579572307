:root {
	--tip-left: 0px;
	--tip-top: 0px;
}

::-webkit-scrollbar {
	width: 0px;
}	

#Resume * {
	text-align: left;
	font-family: 'Roboto';
	font-weight: 400;
}		

#Resume {
	width: 100vw;
	padding-top: 50px;
	margin: -10px 0;
	overflow-x: hidden;
	background: #fff;
}	

#Resume .balls {
	position: fixed;
	width: 100vw;
	height: 100vh;
	opacity: .4;
}	

#Resume .balls div {
	position: absolute;
	height: 300px;
	width: 300px;
	border-radius: 50%;
	transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
}	

#Resume .ball1 {
	left: 70%;
	box-shadow: 0px 0px 20px #F37034;
	background: linear-gradient(0deg, #e82265 0%, #ffc100 100%)
}	

#Resume .ball2 {
	right: 70%;
	box-shadow: 0px 0px 20px #28CD94;
	background: linear-gradient(0deg, #2ffe3d 0%, #229bed 100%);
}	

@media screen and (max-width: 768px) {
	#Resume .balls div {
		box-shadow: none;
	}	
	#Resume {
		padding: 0;
	}	
}	

#Resume .content {
	position: relative;
	padding: 15px 15px 100px;
	margin: 0 auto 100px;
	max-width: 700px;
	border-radius: 10px;
	border: 1px solid #fff2;
	background: #fff1;
}	

/* Font sizes */
#Resume h1 { font-size: 40px }
#Resume h2 { font-size: 30px }
#Resume h3 { font-size: 23px }
#Resume p { font-size: 20px }
#Resume .dropdown { font-size: 20px }

/* Margins */
#Resume h2 { margin-bottom: 40px }
#Resume h3 { margin: 40px 0 5px }

#Resume h1, 
#Resume h2 {
	font-family: 'Montserrat';
}	

#Resume h1, 
#Resume h2, 
#Resume h4 {
	text-align: center;
}	

#Resume h1, 
#Resume h2, 
#Resume h3, 
#Resume h4 {
	color: #2C2C2C;
}	

#Resume h1 {
	color: #000
}	

#Resume p, 
#Resume .dropdown {
	margin: 0;
	line-height: 30px;
	color: #636363;
}	


#Resume hr {
	margin: 60px 0;
	height: 3px;
	border: none;
	border-radius: 3px;
	background: linear-gradient(90deg, #00D1FF 0%,#00FF94 100%)
}	

/* Links */
#Resume a {
	color: #60cfe5;
}

#Resume a:focus {
	color: #0f6576;
}

/* Titulo */
#Resume .title {
	display: flex;
	gap: 5px;
	align-items: center;
	flex-direction: column;
	margin: 50px 0 100px;
}	

#Resume .title h1, 
#Resume .title h4 {
	margin: 5px 0;
}	

#Resume .PDF, #Resume .PDF:focus {
	margin-top: 15px;
	padding: 5px 10px;
	text-decoration: none;
	font-size: 20px;
	color: inherit;
	border-radius: 5px;
	border: 2px solid lightblue;
}


/* Dropdown */
#Resume .dropdown {
	cursor: pointer;
}

#Resume .dropdown svg {
	width: 15px;
	margin: 0 10px;
	transform: rotate(0deg);
}	

#Resume .dropdown ul {
	display: none;
	cursor: default;
}	

#Resume .dropdown.active svg {
	transform: rotate(-180deg);
}	

#Resume .dropdown.active ul {
	display: block;
}	

/* Menu interactivo */
#Resume .interactive a {
	text-decoration: none;
	color: inherit;
}

#Resume .interactive .icons {
	display: flex;
	display: -webkit-flex;
	justify-content: space-evenly;
}	

#Resume .interactive .icons img {
	width: 50px;
	height: 50px;
	transition: transform .1s ease;
	-webkit-transition: transform .1s ease;
}	

#Resume .interactive .icons img:hover {
	transform: scale(1.05);
	-webkit-transform: scale(1.05);
}	

#Resume .interactive .info {
	display: block;
	height: 52px;
	margin-top: 50px;
	
	font-size: 20px;
	text-align: center;
	line-height: 52px;
	text-decoration: none;
	
	border-radius: 4px;
	color: #fafafa;
	background: #30ACC7;
	box-shadow: 0px 6px 20px -10px rgb(0 0 0 / 55%);
	transition: background .3s ease-in-out;
	-webkit-transition: background .3s ease-in-out;
}

#Resume .interactive .info::after {
	content: '';
	position: absolute;
	top: var(--tip-top);
	left: var(--tip-left);
	height: 20px;
	width: 20px;
	background: inherit;
	transform: rotate(45deg);
	transition: left .3s ease-in-out;
	-webkit-transform: rotate(45deg);
	-webkit-transition: left .3s ease-in-out;
}


/* Dark mode */
#Resume.dark p, 
#Resume.dark .dropdown {
	color: #b7b7b7;
}

#Resume.dark h1, 
#Resume.dark h2, 
#Resume.dark h3, 
#Resume.dark h4 {
	color: #fff
}

#Resume.dark h1 {
	color: #fff
}

#Resume.dark {
	background: #171725
}