.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-family: Consolas, 'Poppins';
  font-weight: 200;

  color: white;
  background-color: #282c34;
}

h1, h2, h3 {
  font-weight: 400;
}

input, label {
  cursor: pointer;
}

footer {
  position: absolute;
  bottom: 0;
  color: #a5aec3;
  font-style: italic;
  font-size: .7em;
}

.App-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 20px);
}

.width1000 {  
  width: 100%;
  max-width: 1000px;
  padding: 10px;
  border-radius: 10px;
}

#back {
  position: fixed;
  top: 10px;
  left: 10px;
  text-decoration: none;
  color: white;
  z-index: 10;
}

#back svg:hover {
  border-radius: 50%;
  background: #fff1;
}