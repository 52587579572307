@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-family: Consolas, 'Poppins';
  font-weight: 200;

  color: white;
  background-color: #282c34;
}

h1, h2, h3 {
  font-weight: 400;
}

input, label {
  cursor: pointer;
}

footer {
  position: absolute;
  bottom: 0;
  color: #a5aec3;
  font-style: italic;
  font-size: .7em;
}

.App-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 20px);
}

.width1000 {  
  width: 100%;
  max-width: 1000px;
  padding: 10px;
  border-radius: 10px;
}

#back {
  position: fixed;
  top: 10px;
  left: 10px;
  text-decoration: none;
  color: white;
  z-index: 10;
}

#back svg:hover {
  border-radius: 50%;
  background: #fff1;
}
#about {
  font-size: .9em;
  text-align: left;
  color: #a5aec3;
}

#about > div {
  padding: 10px;
  border: 1px solid #40444e;
  border-radius: 10px;
}

#about h1 {
  margin-top: 5px;
}

#about h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

#about p, #about label {
  font-size: 18px;
}

#about .option {
  display: flex;
  justify-content: space-between;
}

input.switch[type="checkbox"] {
  position: relative;
  width: 40px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
 background: #40444e;
  border-radius: 20px;
  transition: .3s;
}

input.switch:checked[type="checkbox"] {
  background: #03a9f4;
}

input.switch[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 40% !important;
  height: 80%;
  margin-top: 5%;
  left: 5%;
  top: 0;
  border-radius: 20px !important;
  background: #fff;
  transition: .3s;
}

input.switch:checked[type="checkbox"]:before {
  left: 55%;
}

input.switch[type="checkbox"]:focus {
	outline: none;
	border: 1px solid lightgrey;
}
/* container */
.cap-container {
	--grid-min-size: 150px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-min-size), 1fr));
  grid-gap: 10px;
}

/* New Caps */

.cap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	padding: 10px;
	height: 250px;

	cursor: default;
	text-decoration: none;
	border-radius: 10px;
	background: linear-gradient(156deg, #3b3953 50%, #302736 100%);
	box-shadow: 0px 9px 20px 7px #24262b;

	/* Vanilla tilt */
	transform-style: preserve-3d;
	transform: perspective(1000px);
}

.cap svg {
	transform: translateZ(50px) translateY(10px);
}

.cap img {
  margin: 10px;
	width: 60%;
  transform: translateZ(50px) translateY(10px);
}

.cap circle {
  stroke-width: 10;
  fill: transparent;
  cx: 70;
  cy: 70;
  
  transition: stroke-dashoffset .3s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  -moz-transform-origin: 50% 0;
  
  /* filter: drop-shadow(0px 0px 3px #74E7CB); */
}

.cap h1 {
  margin: 10px 0;
  font-size: 25px;
  font-weight: 500;
 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cap button {
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
  color: #ececec;
  border: 1px solid #ececec;
  border-radius: 6px;
  background: transparent;
}

.cap button:hover {
  background: #ffffff0a;
}

/* Stars */

.cap .stars {
  position: absolute;
  width: 100%;
  height: 50%;
	font-family: 'Roboto';
	font-weight: 400;
  color: #8d8d8d;
  z-index: -1;
}

.cap .stars div {
  position: absolute;
}
#listOfGifs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.gif {
  height: 200px;
  font-size: .8em;
  color: white;
  text-decoration: none;
}

.gif img, .gif h3 {
  height: 200px;
}

button, a.link {
  color: white;
  background: #282c34;
  padding: 5px;
  border: 1px solid lightblue;
  border-radius: 5px;
  font-size: 14px;
}

button:hover {
  background: #3f4550;
}

span.loading {
  display: block;
  font-size: 40px;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg)
  }
}

form {
  display: flex;
  justify-content: center;
}

form input {
  width: 80%;
  max-width: 300px;
  padding: 5px;
  cursor: auto;
  border-radius: 5px;
  border: 2px solid #3f4550;
  color: #fff;
  background: #3f4550;
}

form input:focus {
  outline: none;
  background: #111;
  border: 2px solid rebeccapurple;
}

form a {
  color: white;
}
.p5grid {
	--grid-min-size: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-min-size), 1fr));
  grid-gap: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.p5grid .react-p5 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p5grid canvas {
  border-radius: 10px;
}
:root {
	--tip-left: 0px;
	--tip-top: 0px;
}

::-webkit-scrollbar {
	width: 0px;
}	

#Resume * {
	text-align: left;
	font-family: 'Roboto';
	font-weight: 400;
}		

#Resume {
	width: 100vw;
	padding-top: 50px;
	margin: -10px 0;
	overflow-x: hidden;
	background: #fff;
}	

#Resume .balls {
	position: fixed;
	width: 100vw;
	height: 100vh;
	opacity: .4;
}	

#Resume .balls div {
	position: absolute;
	height: 300px;
	width: 300px;
	border-radius: 50%;
	transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
}	

#Resume .ball1 {
	left: 70%;
	box-shadow: 0px 0px 20px #F37034;
	background: linear-gradient(0deg, #e82265 0%, #ffc100 100%)
}	

#Resume .ball2 {
	right: 70%;
	box-shadow: 0px 0px 20px #28CD94;
	background: linear-gradient(0deg, #2ffe3d 0%, #229bed 100%);
}	

@media screen and (max-width: 768px) {
	#Resume .balls div {
		box-shadow: none;
	}	
	#Resume {
		padding: 0;
	}	
}	

#Resume .content {
	position: relative;
	padding: 15px 15px 100px;
	margin: 0 auto 100px;
	max-width: 700px;
	border-radius: 10px;
	border: 1px solid #fff2;
	background: #fff1;
}	

/* Font sizes */
#Resume h1 { font-size: 40px }
#Resume h2 { font-size: 30px }
#Resume h3 { font-size: 23px }
#Resume p { font-size: 20px }
#Resume .dropdown { font-size: 20px }

/* Margins */
#Resume h2 { margin-bottom: 40px }
#Resume h3 { margin: 40px 0 5px }

#Resume h1, 
#Resume h2 {
	font-family: 'Montserrat';
}	

#Resume h1, 
#Resume h2, 
#Resume h4 {
	text-align: center;
}	

#Resume h1, 
#Resume h2, 
#Resume h3, 
#Resume h4 {
	color: #2C2C2C;
}	

#Resume h1 {
	color: #000
}	

#Resume p, 
#Resume .dropdown {
	margin: 0;
	line-height: 30px;
	color: #636363;
}	


#Resume hr {
	margin: 60px 0;
	height: 3px;
	border: none;
	border-radius: 3px;
	background: linear-gradient(90deg, #00D1FF 0%,#00FF94 100%)
}	

/* Links */
#Resume a {
	color: #60cfe5;
}

#Resume a:focus {
	color: #0f6576;
}

/* Titulo */
#Resume .title {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	align-items: center;
	flex-direction: column;
	margin: 50px 0 100px;
}	

#Resume .title h1, 
#Resume .title h4 {
	margin: 5px 0;
}	

#Resume .PDF, #Resume .PDF:focus {
	margin-top: 15px;
	padding: 5px 10px;
	text-decoration: none;
	font-size: 20px;
	color: inherit;
	border-radius: 5px;
	border: 2px solid lightblue;
}


/* Dropdown */
#Resume .dropdown {
	cursor: pointer;
}

#Resume .dropdown svg {
	width: 15px;
	margin: 0 10px;
	transform: rotate(0deg);
}	

#Resume .dropdown ul {
	display: none;
	cursor: default;
}	

#Resume .dropdown.active svg {
	transform: rotate(-180deg);
}	

#Resume .dropdown.active ul {
	display: block;
}	

/* Menu interactivo */
#Resume .interactive a {
	text-decoration: none;
	color: inherit;
}

#Resume .interactive .icons {
	display: flex;
	display: -webkit-flex;
	justify-content: space-evenly;
}	

#Resume .interactive .icons img {
	width: 50px;
	height: 50px;
	transition: transform .1s ease;
	-webkit-transition: transform .1s ease;
}	

#Resume .interactive .icons img:hover {
	transform: scale(1.05);
	-webkit-transform: scale(1.05);
}	

#Resume .interactive .info {
	display: block;
	height: 52px;
	margin-top: 50px;
	
	font-size: 20px;
	text-align: center;
	line-height: 52px;
	text-decoration: none;
	
	border-radius: 4px;
	color: #fafafa;
	background: #30ACC7;
	box-shadow: 0px 6px 20px -10px rgb(0 0 0 / 55%);
	transition: background .3s ease-in-out;
	-webkit-transition: background .3s ease-in-out;
}

#Resume .interactive .info::after {
	content: '';
	position: absolute;
	top: 0px;
	top: var(--tip-top);
	left: 0px;
	left: var(--tip-left);
	height: 20px;
	width: 20px;
	background: inherit;
	transform: rotate(45deg);
	transition: left .3s ease-in-out;
	-webkit-transform: rotate(45deg);
	-webkit-transition: left .3s ease-in-out;
}


/* Dark mode */
#Resume.dark p, 
#Resume.dark .dropdown {
	color: #b7b7b7;
}

#Resume.dark h1, 
#Resume.dark h2, 
#Resume.dark h3, 
#Resume.dark h4 {
	color: #fff
}

#Resume.dark h1 {
	color: #fff
}

#Resume.dark {
	background: #171725
}
section#canban {
  max-width: 800px;
  padding: 20px;
  border: 2px solid #3b414d;
  border-radius: 10px;
}

#canban form, #canban .signout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto 0;
  max-width: 300px;
}

#canban input, #canban button {
  height: 40px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #d7d7d7;
  transition: background .15s ease;
}

#canban input:focus, #canban button:hover {
  background: #3b414d;
}

#canban input, #canban .nota p {
  width: 100%;
  margin-right: 6px;
  text-align: left;
  font-size: 20px;
}

#canban input {
  border: 2px solid #3b414d;
}

#canban button {
  width: 100%;
  cursor: pointer;
}

#canban .nota {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#canban form button, #canban .nota button {
  width: 40px;
  font-size: 20px;
}

#canban .signin img {
  width: 40px;
  height: 40px;
}

#canban .signin {
  width: 50px;
  height: 50px;
}

#brand {
	position: fixed;
	bottom: 5px;
	left: 10px;
  color: #fff2
}
.App {
	padding: 0;
}

#SpaceXSteps {
	background: white;
	scroll-behavior: smooth;
}

#SpaceXSteps article {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	padding: 0 6%;
	font-family: 'Montserrat';
}

#SpaceXSteps ul {
	max-width: 600px;
	padding: 0;
	text-align: left;
	list-style-type: none;
}

#SpaceXSteps li {
	margin-bottom: 10px;
	padding-left: 10px;
	border-left: 1px solid white;
}

#SpaceXSteps h1 {
	font-weight: 100;
	letter-spacing: 4px;
}

#SpaceXSteps p {
	text-align: left;
}
