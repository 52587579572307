.p5grid {
	--grid-min-size: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-min-size), 1fr));
  grid-gap: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.p5grid .react-p5 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p5grid canvas {
  border-radius: 10px;
}